import React from "react"
import { graphql } from "gatsby"
import { Flex, Text, Box } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { CldImage } from '@extend'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Flex flexDirection={['column', 'row']} justifyContent='space-around' alignItems='center' height="100vh">
        <Box mt={['20px', '0']} textAlign={['center', 'right']}>
          <Text as="h1">Not Found</Text>
          <Text as="p">You just hit a route that doesn&#39;t exist... the sadness.</Text>
        </Box>
        <CldImage alt="404" height={'75vh'} sx={{ objectFit: 'cover' }} src='https://res.cloudinary.com/yabuko/image/upload/v1589042281/website/404_crrwdh.jpg' transformations={['e_blackwhite', 'c_scale', 'w_600']}/>
      </Flex>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
